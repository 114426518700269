import { useEffect, useState } from 'react'
import { getIndustryInfo } from '../data'
import { Container, LinearProgress } from '@material-ui/core'
import StockChart from './Fields/StockChart'
import { timeParse } from 'd3-time-format'

function EveMarketStock({ item }) {
    const [stockData, setStockData] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const parseDate = timeParse('%Y-%m-%d')
        const fetchData = () => {
            Promise.all([getIndustryInfo(['market', item, 'stock'])])
                .then((results) => {
                    setStockData(
                        results[0].data.map((x) => {
                            return { ...x, open: x.lowest, close: x.highest, date: parseDate(x.date) }
                        })
                    )
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [item])

    //hybrid

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!stockData) return <LinearProgress />

    return (
        <>
            <h3>Universe stock</h3>
            <StockChart type={'hybrid'} data={stockData} />
        </>
    )
}

export default EveMarketStock
