import { useEffect, useMemo, useState } from 'react'
import { getIndustryInfo } from '../data'
import { Container } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

//const useStyles = makeStyles({})

function EveMarketItem({ item }) {
    const [sellOrders, setSellOrders] = useState([])
    const [buyOrders, setBuyOrders] = useState([])
    const [error, setError] = useState(null)
    //const classes = useStyles()

    const columns = useMemo(
        () => [
            { field: 'marketName', headerName: 'Station', width: 500 },
            {
                field: 'price',
                headerName: 'Price',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'volume_remain',
                headerName: 'Volume',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
        ],
        []
    )

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getIndustryInfo(['market', item])])
                .then((results) => {
                    setSellOrders(results[0].data.filter((x) => x.is_buy_order === 0).sort((a, b) => a.price - b.price))
                    setBuyOrders(results[0].data.filter((x) => x.is_buy_order === 1).sort((a, b) => b.price - a.price))
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [item])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    return (
        <>
            <h3>Sell order</h3>
            <div style={{ height: 300, width: '100%' }}>
                <DataGrid columns={columns} rows={sellOrders} getRowId={(row) => row.order_id} rowHeight={25} />
            </div>
            <h3>Buy order</h3>
            <div style={{ height: 300, width: '100%' }}>
                <DataGrid columns={columns} rows={buyOrders} getRowId={(row) => row.order_id} rowHeight={25} />
            </div>
        </>
    )
}

export default EveMarketItem
